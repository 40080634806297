<!-- 发货详情 -->
<template>
  <div>
    <back />
    <div class="globle_border detail ">
      <header>销售清单详情</header>
      <div style="text-align: right;margin-bottom: 10px;">
        <el-button type="primary" @click="onPrint">打印清单</el-button>
      </div>
      <!-- 表格-->
      <div class="globle_table tableBox">
        <el-table height="390" :data="tableData" style="width: 100%">
          <el-table-column label="序列" show-overflow-tooltip min-width="60" align="center">
            <template slot-scope="scope">
              {{ scope.$index + 1 }} <!-- 这里可以获取到当前行的索引号 -->
            </template>
          </el-table-column>
          <!-- <el-table-column label="商品编号" prop="serialId" show-overflow-tooltip min-width="100" align="center">
          </el-table-column> -->
          <el-table-column label="商品分类" prop="categoryName" show-overflow-tooltip min-width="100" align="center">
          </el-table-column>
          <el-table-column label="商品品牌" prop="brand" show-overflow-tooltip min-width="100" align="center">
          </el-table-column>
          <el-table-column label="商品名称" prop="productName" show-overflow-tooltip min-width="150" align="center">
          </el-table-column>
          <el-table-column label="商品规格" prop="specs" show-overflow-tooltip min-width="100" align="center">
          </el-table-column>
          <el-table-column prop="enterBox" label="入箱数" show-overflow-tooltip min-width="100" align="center">
          </el-table-column>
          <!-- <el-table-column prop="purchaseCount" label="订单数量" show-overflow-tooltip min-width="100" align="center">
          </el-table-column> -->
          <el-table-column prop="productBox" label="订单箱数" show-overflow-tooltip min-width="100" align="center">
          </el-table-column>
          <!-- <el-table-column prop="deliveryCount" label="销售数量" show-overflow-tooltip min-width="100" align="center">
          </el-table-column> -->
          <el-table-column prop="deliveryBox" label="销售箱数" show-overflow-tooltip min-width="100" align="center">
          </el-table-column>
          <el-table-column prop="receiveBox" label="收货箱数" show-overflow-tooltip min-width="100" align="center">
          </el-table-column>
          <el-table-column label="销售单价" prop="supplyPrice" show-overflow-tooltip min-width="100" align="center">
          </el-table-column>
          <el-table-column label="销售总价(元)" prop="totalAmount" show-overflow-tooltip min-width="110" align="center">
          </el-table-column>
          <el-table-column label="生产日期" prop="productDate" show-overflow-tooltip min-width="100" align="center">
          </el-table-column>
          <el-table-column label="商品保质期(天)" prop="expirationDay" show-overflow-tooltip min-width="120" align="center">
          </el-table-column>
          <el-table-column label="商品条形码" prop="barCode" show-overflow-tooltip min-width="150" align="center">
          </el-table-column>
          <el-table-column label="生产厂家" prop="factoryName" show-overflow-tooltip fixed="right" min-width="220"
            align="center">
          </el-table-column>
        </el-table>
        <div class="bottom">
          <div>金额总计：<span style="color: red;font-weight: bolder;font-size: 20px;">
              ￥{{ Number(ruleForm.totalPrice).toFixed(2) }}
            </span>
          </div>
        </div>
      </div>
    </div>
    <!-- 收货人信息 -->
    <div class=" num-item">
      <header>
        <div>送货人信息</div>
        <div>收货人信息</div>
      </header>
      <div class="detInfo">
        <div class="Info ">
          <!-- <header> 送货人信息 </header> -->
          <div>供应商代码：{{ ruleForm.supplierCode }}</div>
          <div>送货人姓名：{{ ruleForm.deliveryPerson }}</div>
          <div>送货人手机号：{{ ruleForm.deliveryPhone }}</div>
          <div>车牌号：{{ ruleForm.carNum }}</div>
        </div>
        <div class="Info">
          <!-- <header> 收货人信息 </header> -->
          <div>商超代码：{{ ruleForm.shopNum }}</div>
          <div>收货人姓名：{{ ruleForm.contactMan }}</div>
          <div>收货人手机号：{{ ruleForm.contactTel }}</div>
          <div>收货地址：{{ ruleForm.address }}</div>
          <div>签收时间：{{ ruleForm.signTime }}</div>
        </div>

      </div>

    </div>
    <!-- 打印页面盒子 -->
    <div v-show="false" id="printContent">
      <div class="content">
        <div class="shipping-order">
          <h1 style="text-align: center;margin-bottom: 10px;">发货单</h1>
          <div class="order-details">
            <div style="display: flex;justify-content: space-between;">
              <p style="width: 70%;"><strong>发货时间:</strong>{{ ruleForm.deliveryTime }}</p>
              <p style="width: 50%;"><strong>发货单号:</strong>{{ ruleForm.deliveryNum }}</p>
            </div>
            <div style="display: flex;justify-content: space-between;">
              <p style="width: 70%;"><strong>客户姓名:</strong>{{ ruleForm.contactMan }}</p>
              <p style="width: 50%;"><strong>联系电话:</strong>{{ ruleForm.contactTel }}</p>
            </div>
            <p><strong>购货单位:</strong>{{ ruleForm.shopName }}</p>
            <p><strong>收货地址:</strong>{{ ruleForm.address }}</p>
          </div>
          <table class="order-items">
            <thead>
              <tr>
                <th style="text-align: center;">序号</th>
                <th class="product-name">商品名称</th>
                <th>单价(元)</th>
                <th v-if="ruleForm.deliveryStatus == 3">收货箱数</th>
                <th v-else>销售箱数</th>
                <th>数量</th>
                <th>小计</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item, index in tableData" :key="item.productId">
                <td style="text-align: center;">{{ index + 1 }}</td>
                <td class="product-name">{{ item.productName }}</td>
                <td>￥{{ item.supplyPrice }}</td>
                <td v-if="ruleForm.deliveryStatus == 3">{{ item.receiveBox }}</td>
                <td v-else>{{ item.deliveryBox }}</td>
                <td>{{ item.deliveryCount }}</td>
                <td>￥{{ item.totalAmount }}</td>
              </tr>
              <tr style="font-weight: bold;">
                <td colspan="5" style="text-align: right;">总计:</td>
                <td>￥{{ Number(ruleForm.totalPrice).toFixed(2) }}</td>
              </tr>
            </tbody>
          </table>
          <div class="order-details" style="margin-top: 8px;">
            <div style="display: flex;justify-content: space-between;">
              <p style="width: 70%;"><strong>送货人姓名:</strong>{{ ruleForm.deliveryPerson }}</p>
              <p style="width: 50%;"><strong>联系电话:</strong>{{ ruleForm.deliveryPhone }}</p>
            </div>
            <p><strong>发货单位:</strong>{{ ruleForm.supplierName }}</p>
            <p style="margin-top: 10px;"><strong>收货人签字:</strong></p>
            <p style="margin-top: 10px;"><strong>签收日期:</strong></p>
          </div>
          <p class="note">注：请仔细核对商品信息，如有误请联系客服。</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      // 表格信息
      tableData: [],
      // 收货人信息
      ruleForm: {},
    }
  },
  created() {
    this.getRequest()
  },
  methods: {
    // 请求列表
    getRequest() {
      this.loading = true;
      this.$axios.get(this.$api.sendDeliveryInfo, {
        params: {
          deliveryId: this.$route.query.deliveryId,
        }
      }).then((res) => {
        const { code, result } = res.data
        if (code == 100) {
          this.ruleForm = result
          this.tableData = result.deliveryProductInfoVos
          this.loading = false;
        }
      }).catch((err) => {
        console.log(err);
        this.loading = false;
      })

    },
    // 打印
    onPrint() {
      // 用户体验不佳
      let oldStr = window.document.body.innerHTML;
      let newStr = document.getElementById('printContent').innerHTML;

      window.document.body.innerHTML = newStr;
      window.print();
      window.document.body.innerHTML = oldStr;
      window.location.reload();
    },

  }
}
</script>

<style scoped lang='scss'>
@media print {

  @page {
    size: A4;
    // margin: 22px 10px 16px; //去除页眉
    // margin-bottom: 22px; //去除页脚
    margin-top: 22px; //去除页眉
  }


  // 强制分页
  .page-break {
    page-break-after: always;
  }

  body {
    margin: 0 auto;
    font-family: Arial, sans-serif;
  }

  .content {
    padding: 10px;

    .shipping-order {
      margin: 20px auto;
      padding: 0px 20px;
    }

    .order-details p {
      margin-bottom: 8px;
      font-size: 20px;
    }

    .order-items {
      width: 100%;
      border-collapse: collapse;
      margin-top: 20px;

      .product-name {
        width: 40%;
        text-align: center;
      }
    }

    .order-items th,
    .order-items td {
      font-size: 18px;
      border: 1px solid #0e0d0d;
      padding: 8px;
      text-align: left;
    }

    .order-items th {
      background-color: #f2f2f2;
    }

    .note {
      // position: fixed;
      // bottom: 22px;
      margin-top: 22px;
      font-size: 15px;
      color: #666;
    }
  }
}

.detail {
  border-radius: 6px;
  box-sizing: border-box;

  header {
    height: 45px;
    text-align: center;
    font-weight: bold;
    font-size: 16px;
    color: #1A1A1A;
  }

  .bottom {
    display: flex;
    align-items: flex-end;
    color: #505554;
    justify-content: flex-end;
    padding: 10px 0;

    div {
      margin-right: 20px;

    }
  }
}

.num-item {
  header {
    background-color: #f1f0f0;
    font-weight: bold;
    font-size: 16px;
    color: #1A1A1A;
    display: flex;
    height: 45px;
    align-items: center;
    text-align: center;
    margin-bottom: 10px;

    div {
      flex: 1;
    }
  }
}


.detInfo {
  display: flex;

  .Info {
    flex: 1;
    padding-left: 100px;
    position: relative;

    div {
      height: 30px;
      line-height: 30px;
    }

    &:first-child:after {
      content: "";
      position: absolute;
      right: 0;
      top: 50%;
      transform: translate(0, -50%);
      width: 0;
      height: 100px;
      border: 1px solid #dcdcdc;
    }
  }

}
</style>